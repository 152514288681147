import Checkbox from 'components/core/Checkbox';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { formatDate, formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { translate } from 'utils/translation';
import { ReportRow } from './field_types';

export const SALES_ITEMS_REPORT_FIELDS_NA = [
  {
    name: 'Transaction Date',
    id: 'transaction_date',
    key: 'transaction_date',
    type: 'DATE',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatLocalDate(row.transaction_date),
  },
  {
    name: 'Transaction Time',
    id: 'transaction_time',
    key: 'transaction_time',
    sortable: true,
    filterable: true,
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.transaction_date, 'LT'),
  },
  {
    name: 'Close Date',
    id: 'close_date',
    key: 'close_date',
    type: 'DATE',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatLocalDate(row.close_date),
  },
  {
    name: 'Close Time',
    id: 'close_time',
    key: 'close_time',
    sortable: true,
    filterable: true,
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.close_date, 'LT'),
  },
  {
    name: 'Canceled Date',
    id: 'canceled_date',
    key: 'canceled_date',
    type: 'DATE',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatDate(row.canceled_date),
  },
  {
    name: 'Last Received Date',
    id: 'last_received_date',
    key: 'last_received_date',
    type: 'DATE',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatDate(row.last_received_date),
  },
  {
    name: 'Transaction #',
    id: 'transaction_id',
    key: 'transaction_id',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/transactions/${row.order_uuid}`}
      >
        {row.transaction_id}
      </Link>
    ),
  },
  {
    name: 'Transaction Type',
    id: 'transaction_type',
    key: 'transaction_type',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Tracking #',
    id: 'tracking_number',
    key: 'tracking_number',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Last MR #',
    key: 'last_mr',
    id: 'last_mr',
    type: 'LINK',
    render: (row: ReportRow) =>
      row.merch_record_id ? (
        <Link
          className='table-row-link'
          id='app-link'
          onClick={e => e.stopPropagation()}
          to={`/receives/${row.merch_uuid}`}
        >
          {row.merch_record_id}
        </Link>
      ) : null,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Note',
    key: 'note',
    id: 'note',
    render: (row: ReportRow) => row.note,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Internal Note',
    key: 'internal_note',
    id: 'internal_note',
    render: (row: ReportRow) => row.internal_note,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-note') ? i18n.t('sku:stock-note') : 'Stock # - Note',
    key: 'sku_note',
    id: 'sku_note',
    render: (row: ReportRow) => row.sku_note,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-warning-note')
      ? i18n.t('sku:stock-warning-note')
      : 'Stock # - Warning Note',
    key: 'sku_warning_note',
    id: 'sku_warning_note',
    render: (row: ReportRow) => row.sku_warning_note,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:days-on-hand') ? i18n.t('sku:days-on-hand') : 'Days On Hand',
    key: 'days_on_hand',
    id: 'days_on_hand',
    render: (row: ReportRow) => row.days_on_hand,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-number') ? i18n.t('sku:stock-number') : 'Stock #',
    id: 'sku',
    key: 'sku',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link className='table-row-link' id='app-link' onClick={e => e.stopPropagation()} to={`/skus/${row.sku_uuid}`}>
        {row.sku}
      </Link>
    ),
  },
  {
    name: 'Description',
    id: 'description',
    key: 'description',
    sortable: true,
    filterable: true,
    isDefault: true,
  },
  {
    name: 'Product #',
    id: 'product_number',
    key: 'product_number',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
  },
  {
    name: 'Client Name',
    id: 'customer_name',
    key: 'customer_name',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => {
      if (row.account_uuid) {
        return row.account_name;
      }

      return row.customer_name;
    },
  },
  {
    name: 'Client #',
    id: 'customer_id',
    key: 'customer_id',
    type: 'LINK',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={row.account_uuid ? `/accounts/${row.account_uuid}` : `/clients/${row.customer_uuid}`}
      >
        {row.account_uuid ? row.account_name : row.customer_id}
      </Link>
    ),
  },
  {
    name: 'Client Email',
    id: 'customer_email',
    key: 'customer_email',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Salesperson #',
    id: 'salesperson_id',
    key: 'salesperson_id',
    isDefault: true,
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Salesperson Name',
    id: 'salesperson_name',
    key: 'salesperson_name',
    sortable: true,
    filterable: true,
    isDefault: true,
  },
  {
    name: translate('reports', 'clerk-name', 'Clerk Name'),
    id: 'clerk_name',
    key: 'clerk_name',
    sortable: true,
    filterable: true,
    isDefault: true,
    render: (row: ReportRow) => {
      if (!row.clerk_first_name && !row.clerk_last_name) {
        return translate('common', 'not-applicable', 'N/A');
      }

      return translate('formats', 'full-name-format', `${row.clerk_first_name} ${row.clerk_last_name}`, {
        firstName: row.clerk_first_name,
        lastName: row.clerk_last_name,
      });
    },
  },
  {
    name: 'Payment Type',
    id: 'payment_type',
    key: 'payment_type',
    isRestricted: true,
    sortable: true,
    filterable: true,
    isDefault: true,
    render: (row: ReportRow) => row.payment_type && row.payment_type.length > 0 && row.payment_type.join(', '),
  },
  {
    name: 'Last Payment Date',
    id: 'last_pay_date',
    key: 'last_pay_date',
    type: 'DATE',
    isRestricted: true,
    sortable: true,
    filterable: true,
    isDefault: true,
    render: (row: ReportRow) => formatLocalDate(row.last_pay_date),
  },
  {
    name: 'Vendor Name',
    id: 'vendor_name',
    key: 'vendor_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Vendor Code',
    id: 'vendor_code',
    key: 'vendor_code',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Location',
    id: 'location_name',
    key: 'location_name',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Source',
    id: 'marketing_source',
    key: 'marketing_source',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Alternative Source',
    id: 'marketing_source_2',
    key: 'marketing_source_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Warranty',
    id: 'warranty',
    key: 'warranty',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Serial #',
    id: 'serial',
    key: 'serial',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-category') ? i18n.t('sku:stock-category') : 'Stock Category',
    id: 'sku_category',
    key: 'sku_category',
    isRestricted: true,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-category-code')
      ? i18n.t('sku:stock-category-code')
      : 'Stock Category Code',
    id: 'sku_category_code',
    key: 'sku_category_code',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Item Type Category',
    id: 'item_type_category',
    key: 'item_type_category',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Item Type Subcategory',
    id: 'item_type_sub_category',
    key: 'item_type_sub_category',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Job Type',
    id: 'job_type',
    key: 'job_type',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Memo',
    id: 'memo',
    key: 'memo',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'common:preowned') ? i18n.t('common:preowned') : 'Preowned',
    id: 'is_preowned',
    key: 'is_preowned',
    isRestricted: true,
    sortable: true,
    render: row => <Checkbox checked={row.is_preowned} disabled />,
  },
  {
    name: 'Quantity',
    id: 'quantity',
    key: 'quantity',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => row.quantity.toFixed(2),
  },
  {
    name: 'Retail',
    id: 'retail',
    key: 'retail',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.retail),
  },
  {
    name: 'Online Price',
    id: 'online_price',
    key: 'online_price',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: false,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.online_price),
  },
  {
    name: 'Tax',
    id: 'tax',
    key: 'tax',
    type: 'NUMBER',
    isRestricted: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.tax),
  },
  {
    name: 'Discount',
    id: 'discount',
    key: 'discount',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: true,
    isRestricted: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.discount),
  },
  {
    name: 'Discount %',
    id: 'discount_pct',
    key: 'discount_pct',
    type: 'PERCENT', // Required for PERCENT
    part: 'discount', // Required for PERCENT
    whole: 'retail', // Required for PERCENT
    isDefault: true,
    isRestricted: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => `${(Number(row.discount_pct) * 100).toFixed(2)}%`,
  },
  {
    name: 'Ext. Price',
    id: 'ext_price',
    key: 'ext_price',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.ext_price),
  },
  {
    name: 'Gross Profit',
    id: 'gross_profit',
    key: 'gross_profit',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: false,
    isRestricted: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.gross_profit || 0),
  },
  {
    name: 'Gross Profit %',
    id: 'gross_profit_pct',
    key: 'gross_profit_pct',
    type: 'PERCENT',
    part: 'gross_profit',
    whole: 'ext_price',
    isDefault: false,
    isRestricted: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => `${(Number(row.gross_profit_pct) * 100).toFixed(2)}%`,
  },
  {
    name: 'Received Date',
    key: 'received_date',
    id: 'received_date',
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.received_date),
    sortable: true,
    isDefault: false,
    filterable: true,
  },
  {
    name: 'Days To Sale',
    id: 'days_to_sale',
    key: 'days_to_sale',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Amount Paid',
    id: 'amount_paid',
    key: 'amount_paid',
    type: 'NUMBER',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.amount_paid),
  },
  {
    name: 'Most Recent Payment Amount',
    id: 'most_recent_payment_amount',
    key: 'most_recent_payment_amount',
    type: 'NUMBER',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.most_recent_payment_amount),
  },
  {
    name: 'Status',
    id: 'status',
    key: 'status',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Cost',
    id: 'cost',
    key: 'cost',
    type: 'NUMBER',
    isCurrency: true,
    sortable: true,
    filterable: true,
    isRestricted: true,
    render: (row: ReportRow) => formatMoney(row.cost),
  },
  {
    name: 'Job Cost',
    id: 'job_cost',
    key: 'job_cost',
    type: 'NUMBER',
    isCurrency: true,
    sortable: true,
    filterable: true,
    isRestricted: true,
    render: (row: ReportRow) => formatMoney(row.job_cost),
  },
  {
    name: 'Billing - Street',
    id: 'billing_street',
    key: 'billing_street',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Street2',
    id: 'billing_street_2',
    key: 'billing_street_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - City',
    id: 'billing_city',
    key: 'billing_city',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - State',
    id: 'billing_state',
    key: 'billing_state',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Postal',
    id: 'billing_postal',
    key: 'billing_postal',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Country',
    id: 'billing_country',
    key: 'billing_country',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Street',
    id: 'shipping_street',
    key: 'shipping_street',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Street2',
    id: 'shipping_street_2',
    key: 'shipping_street_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - City',
    id: 'shipping_city',
    key: 'shipping_city',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - State',
    id: 'shipping_state',
    key: 'shipping_state',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Postal',
    id: 'shipping_postal',
    key: 'shipping_postal',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Country',
    id: 'shipping_country',
    key: 'shipping_country',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Image',
    id: 'image',
    key: 'image',
    type: 'IMAGE',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) =>
      row.product_image_url && (
        <img
          alt=''
          id='product-image-table'
          src={row.product_image_url}
          style={{ maxHeight: '100px', minHeight: '50px' }}
        />
      ),
  },
  {
    name: 'Custom 1',
    id: 'custom_1',
    key: 'custom_1',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 2',
    id: 'custom_2',
    key: 'custom_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 3',
    id: 'custom_3',
    key: 'custom_3',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 4',
    id: 'custom_4',
    key: 'custom_4',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 5',
    id: 'custom_5',
    key: 'custom_5',
    sortable: true,
    filterable: true,
  },
];

export const SALES_ITEMS_REPORT_FIELDS_EU = [
  {
    name: 'Transaction Date',
    id: 'transaction_date',
    key: 'transaction_date',
    type: 'DATE',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatLocalDate(row.transaction_date),
  },
  {
    name: 'Close Date',
    id: 'close_date',
    key: 'close_date',
    type: 'DATE',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatLocalDate(row.close_date),
  },
  {
    name: 'Canceled Date',
    id: 'canceled_date',
    key: 'canceled_date',
    type: 'DATE',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatDate(row.canceled_date),
  },
  {
    name: 'Last Received Date',
    id: 'last_received_date',
    key: 'last_received_date',
    type: 'DATE',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatDate(row.last_received_date),
  },
  {
    name: 'Transaction #',
    id: 'transaction_id',
    key: 'transaction_id',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/transactions/${row.order_uuid}`}
      >
        {row.transaction_id}
      </Link>
    ),
  },
  {
    name: 'Transaction Type',
    id: 'transaction_type',
    key: 'transaction_type',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Tracking #',
    id: 'tracking_number',
    key: 'tracking_number',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Last MR #',
    key: 'last_mr',
    id: 'last_mr',
    type: 'LINK',
    render: (row: ReportRow) =>
      row.merch_record_id ? (
        <Link
          className='table-row-link'
          id='app-link'
          onClick={e => e.stopPropagation()}
          to={`/receives/${row.merch_uuid}`}
        >
          {row.merch_record_id}
        </Link>
      ) : null,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Note',
    key: 'note',
    id: 'note',
    render: (row: ReportRow) => row.note,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Internal Note',
    key: 'internal_note',
    id: 'internal_note',
    render: (row: ReportRow) => row.internal_note,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-note') ? i18n.t('sku:stock-note') : 'Stock # - Note',
    key: 'sku_note',
    id: 'sku_note',
    render: (row: ReportRow) => row.sku_note,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-warning-note') ? i18n.t('sku:stock-warning-note') : 'Stock # - Note',
    key: 'sku_warning_note',
    id: 'sku_warning_note',
    render: (row: ReportRow) => row.sku_warning_note,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:days-on-hand') ? i18n.t('sku:days-on-hand') : 'Days On Hand',
    key: 'days_on_hand',
    id: 'days_on_hand',
    render: (row: ReportRow) => row.days_on_hand,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-number') ? i18n.t('sku:stock-number') : 'Stock #',
    id: 'sku',
    key: 'sku',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link className='table-row-link' id='app-link' onClick={e => e.stopPropagation()} to={`/skus/${row.sku_uuid}`}>
        {row.sku}
      </Link>
    ),
  },
  {
    name: 'Description',
    id: 'description',
    key: 'description',
    sortable: true,
    filterable: true,
    isDefault: true,
  },
  {
    name: 'Product #',
    id: 'product_number',
    key: 'product_number',
    type: 'LINK',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
  },
  {
    name: 'Client Name',
    id: 'customer_name',
    key: 'customer_name',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => {
      if (row.account_uuid) {
        return row.account_name;
      }

      return row.customer_name;
    },
  },
  {
    name: 'Client #',
    id: 'customer_id',
    key: 'customer_id',
    type: 'LINK',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={row.account_uuid ? `/accounts/${row.account_uuid}` : `/clients/${row.customer_uuid}`}
      >
        {row.account_uuid ? row.account_name : row.customer_id}
      </Link>
    ),
  },
  {
    name: 'Client Email',
    id: 'customer_email',
    key: 'customer_email',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Salesperson #',
    id: 'salesperson_id',
    key: 'salesperson_id',
    isDefault: true,
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Salesperson Name',
    id: 'salesperson_name',
    key: 'salesperson_name',
    sortable: true,
    filterable: true,
    isDefault: true,
  },
  {
    name: 'Payment Type',
    id: 'payment_type',
    key: 'payment_type',
    isRestricted: true,
    sortable: true,
    filterable: true,
    isDefault: true,
    render: (row: ReportRow) => row.payment_type && row.payment_type.length > 0 && row.payment_type.join(', '),
  },
  {
    name: 'Last Payment Date',
    id: 'last_pay_date',
    key: 'last_pay_date',
    type: 'DATE',
    isRestricted: true,
    sortable: true,
    filterable: true,
    isDefault: true,
    render: (row: ReportRow) => formatLocalDate(row.last_pay_date),
  },
  {
    name: 'Vendor Name',
    id: 'vendor_name',
    key: 'vendor_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Vendor Code',
    id: 'vendor_code',
    key: 'vendor_code',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Location',
    id: 'location_name',
    key: 'location_name',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Source',
    id: 'marketing_source',
    key: 'marketing_source',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Alternative Source',
    id: 'marketing_source_2',
    key: 'marketing_source_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Warranty',
    id: 'warranty',
    key: 'warranty',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Serial #',
    id: 'serial',
    key: 'serial',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-category') ? i18n.t('sku:stock-category') : 'Stock Category',
    id: 'sku_category',
    key: 'sku_category',
    isRestricted: true,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'sku:stock-category-code')
      ? i18n.t('sku:stock-category-code')
      : 'Stock Category Code',
    id: 'sku_category_code',
    key: 'sku_category_code',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Item Type Category',
    id: 'item_type_category',
    key: 'item_type_category',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Item Type Subcategory',
    id: 'item_type_sub_category',
    key: 'item_type_sub_category',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Job Type',
    id: 'job_type',
    key: 'job_type',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Memo',
    id: 'memo',
    key: 'memo',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Preowned',
    id: 'is_preowned',
    key: 'is_preowned',
    isRestricted: true,
    sortable: true,
    render: row => <Checkbox checked={row.is_preowned} disabled />,
  },
  {
    name: 'Quantity',
    id: 'quantity',
    key: 'quantity',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => row.quantity.toFixed(2),
  },
  {
    name: 'Retail',
    id: 'retail',
    key: 'retail',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.retail),
  },
  {
    name: 'Online Price',
    id: 'online_price',
    key: 'online_price',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: false,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.online_price),
  },
  {
    name: 'VAT',
    id: 'vat',
    key: 'vat',
    type: 'NUMBER',
    isRestricted: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.vat),
  },
  {
    name: 'Discount',
    id: 'discount',
    key: 'discount',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: true,
    isRestricted: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.discount),
  },
  {
    name: 'Discount %',
    id: 'discount_pct',
    key: 'discount_pct',
    type: 'PERCENT', // Required for PERCENT
    part: 'discount', // Required for PERCENT
    whole: 'retail', // Required for PERCENT
    isDefault: true,
    isRestricted: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => `${(Number(row.discount_pct) * 100).toFixed(2)}%`,
  },
  {
    name: 'Cost',
    id: 'cost',
    key: 'cost',
    type: 'NUMBER',
    isCurrency: true,
    sortable: true,
    filterable: true,
    isRestricted: true,
    render: (row: ReportRow) => formatMoney(row.cost),
  },
  {
    name: 'Ext. Price',
    id: 'ext_price',
    key: 'ext_price',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.ext_price),
  },
  {
    name: 'Ext. Price (Ex-VAT)',
    id: 'ext_price_ex_vat',
    key: 'ext_price_ex_vat',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.ext_price_ex_vat),
  },
  {
    name: 'Gross Profit',
    id: 'gross_profit_ex_vat',
    key: 'gross_profit_ex_vat',
    type: 'NUMBER',
    isCurrency: true,
    isDefault: false,
    isRestricted: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.gross_profit_ex_vat || 0),
  },
  {
    name: 'Gross Profit %',
    id: 'gross_profit_ex_vat_pct',
    key: 'gross_profit_ex_vat_pct',
    type: 'PERCENT',
    part: 'gross_profit_ex_vat',
    whole: 'ext_price_ex_vat',
    isDefault: false,
    isRestricted: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => `${(Number(row.gross_profit_ex_vat_pct) * 100).toFixed(2)}%`,
  },
  {
    name: 'Received Date',
    key: 'received_date',
    id: 'received_date',
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.received_date),
    sortable: true,
    isDefault: false,
    filterable: true,
  },
  {
    name: 'Days To Sale',
    id: 'days_to_sale',
    key: 'days_to_sale',
    isRestricted: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Amount Paid',
    id: 'amount_paid',
    key: 'amount_paid',
    type: 'NUMBER',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.amount_paid),
  },
  {
    name: 'Most Recent Payment Amount',
    id: 'most_recent_payment_amount',
    key: 'most_recent_payment_amount',
    type: 'NUMBER',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.most_recent_payment_amount),
  },
  {
    name: 'Status',
    id: 'status',
    key: 'status',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Street',
    id: 'billing_street',
    key: 'billing_street',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Street2',
    id: 'billing_street_2',
    key: 'billing_street_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - City',
    id: 'billing_city',
    key: 'billing_city',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - State',
    id: 'billing_state',
    key: 'billing_state',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Postal',
    id: 'billing_postal',
    key: 'billing_postal',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Country',
    id: 'billing_country',
    key: 'billing_country',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Street',
    id: 'shipping_street',
    key: 'shipping_street',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Street2',
    id: 'shipping_street_2',
    key: 'shipping_street_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - City',
    id: 'shipping_city',
    key: 'shipping_city',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - State',
    id: 'shipping_state',
    key: 'shipping_state',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Postal',
    id: 'shipping_postal',
    key: 'shipping_postal',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Country',
    id: 'shipping_country',
    key: 'shipping_country',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Image',
    id: 'image',
    key: 'image',
    type: 'IMAGE',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) =>
      row.product_image_url && (
        <img
          alt=''
          id='product-image-table'
          src={row.product_image_url}
          style={{ maxHeight: '100px', minHeight: '50px' }}
        />
      ),
  },
  {
    name: 'Custom 1',
    id: 'custom_1',
    key: 'custom_1',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 2',
    id: 'custom_2',
    key: 'custom_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 3',
    id: 'custom_3',
    key: 'custom_3',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 4',
    id: 'custom_4',
    key: 'custom_4',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Custom 5',
    id: 'custom_5',
    key: 'custom_5',
    sortable: true,
    filterable: true,
  },
];
