import { cloneDeep, isEmpty, set } from 'lodash';

const IS_DEBUG = process.env.NODE_ENV === 'development';

export enum PrintParams {
  AutoPrint = 'autoPrint',
  Gift = 'gift',
  ID = 'id',
  SkuID = 'sku_id',
  Transaction = 'transaction',
  Type = 'type',
  WithDueDate = 'dueDate',
  WithImage = 'primaryImage',
  WithPrintIcon = 'printIcon',
  Demo = 'demo',
  Date = 'date',
  Fields = 'fields',
  Filters = 'filters',
  AdditionalReportData = 'additional_report_data',
  OneSided = 'one_sided',
  Token = 'token',
  Dynamic = 'dynamic',
  Preview = 'preview',
  Title = 'title',
}

// TODO: Determine whether this should be merged with Transaction Type (if it exists)
export enum PrintType {
  Collection = 'collection',
  Invoice = 'invoice',
  Job = 'job',
  Memo = 'memo',
  Purchase = 'purchase',
  Standard = 'standard-receipt',
  Tag = 'tag',
  ThermalReceipt = 'thermal-receipt',
  ThermalReceiptJob = 'thermal-receipt-job',
  TwoSidedJob = 'two-sided-job',
  Wholesale = 'wholesale',
  StoreCredit = 'store-credit',
  MerchReceived = 'merch-received',
  Transfer = 'transfer',
  Tags = 'tags',
  DailyPayments = 'daily-payments',
  Report = 'report',
  CollectionStatement = 'collection-statement',
  Appraisals = 'appraisals',
  StatementOfValue = 'statement-of-value',
  OnAccountStatement = 'on-acount-statement',
  HistoricalOnAccountStatement = 'historical-on-acount-statement',
  OnAccountStatements = 'on-account-statements',
  MarmaladeInvoice = 'marmalade-invoice',
  CertificateInvoice = 'certificate-invoice',
  CertificateOfSale = 'certificate-of-sale',
  MarmaladeJob = 'marmalade-job',
}

export const PrintAddOnTypes = {
  JewelersMutual: 'JM',
} as const;

export const openPrintWindow = ({
  type,
  transaction,
  id,
  withDueDate = false,
  withImage = false,
  withPrintIcon = false,
  gift = false,
  newWindow = !IS_DEBUG,
  autoPrint = !IS_DEBUG,
  demo = false,
  date = '',
  filters = false,
  fields = false,
  additionalReportData = false,
  oneSided = false,
  isDynamic = false,
  preview = {} as any,
  title = '',
}) => {
  if (!type) return;

  const isAutoPrint = autoPrint && !demo;
  const isNewWindow = newWindow && !demo;

  let baseUrl = `/print?${PrintParams.Type}=${type}${id ? `&${PrintParams.ID}=${id}` : ''}`;

  baseUrl += transaction ? `&${PrintParams.Transaction}=${transaction}` : '';
  baseUrl += title ? `&${PrintParams.Title}=${title}` : '';
  baseUrl += withDueDate ? `&${PrintParams.WithDueDate}=y` : '';
  baseUrl += withImage ? `&${PrintParams.WithImage}=y` : '';
  baseUrl += withPrintIcon ? `&${PrintParams.WithPrintIcon}=y` : '';
  baseUrl += gift ? `&${PrintParams.Gift}=y` : '';
  baseUrl += type === PrintType.Tag ? `&${PrintParams.SkuID}=${id}` : '';
  baseUrl += date ? `&${PrintParams.Date}=${date}` : '';
  baseUrl += isAutoPrint ? `&${PrintParams.AutoPrint}=y` : '';
  baseUrl += oneSided ? `&${PrintParams.OneSided}=y` : '';

  baseUrl += filters ? `&${PrintParams.Filters}=${JSON.stringify(filters)}` : '';
  baseUrl += fields ? `&${PrintParams.Fields}=${JSON.stringify(fields).replace('#', 'Number')}` : '';
  baseUrl += additionalReportData
    ? `&${PrintParams.AdditionalReportData}=${JSON.stringify(additionalReportData).replace('#', 'Number')}`
    : '';
  baseUrl += isDynamic ? `&${PrintParams.Dynamic}=y` : '';

  if (!isEmpty(preview)) {
    if (type === 'preview') {
      const newPreview = cloneDeep(preview);
      baseUrl += `&preview=${JSON.stringify(
        set(
          newPreview,
          'attributes.footer.terms_and_conditions.text',
          window.btoa(newPreview.attributes.footer.terms_and_conditions.text)
        )
      )}`;
    } else {
      baseUrl += `&preview=${JSON.stringify(preview)}`;
    }
  }

  if (isNewWindow) {
    const WINDOW_HEIGHT = 800;
    const WINDOW_WIDTH = 1200;
    const offsetLeft = window.outerWidth / 2 + window.screenY - WINDOW_WIDTH / 2;
    const offsetTop = window.outerHeight / 2 - window.screenX - WINDOW_HEIGHT / 2;

    window.open(
      baseUrl,
      'Crystal_Print_Window_Name',
      `height=${WINDOW_HEIGHT},width=${WINDOW_WIDTH},left=${offsetLeft},top=${offsetTop}`
    );
  } else {
    window.open(baseUrl, '_blank');
  }
};

export const openPrintStoreCredit = (clientId: string) => {
  const IS_DEBUG = process.env.NODE_ENV === 'development';
  const baseUrl = `/print?type=store-credit&id=${clientId}`;
  if (!IS_DEBUG) {
    const WINDOW_HEIGHT = 800;
    const WINDOW_WIDTH = 1200;
    const offsetLeft = window.outerWidth / 2 + window.screenY - WINDOW_WIDTH / 2;
    const offsetTop = window.outerHeight / 2 - window.screenX - WINDOW_HEIGHT / 2;

    window.open(
      baseUrl,
      'Crystal_Print_Client_Store_Credit_Window_Name',
      `height=${WINDOW_HEIGHT},width=${WINDOW_WIDTH},left=${offsetLeft},top=${offsetTop}`
    );
  } else {
    window.open(baseUrl, '_blank');
  }
};
